import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import ReportIssueForm from "../sections/contact-us/ReportIssueForm"
import contactUsHeroBgUrl from "../../media/contact-us-banner-overlay.png"

const contactUsHeroTitle = "Report Issue"
const contactUsHeroDescription = ""

const ReportIssuePage = ({ location }: PageProps) => (
    <Layout location={location}>
        <SEO title="Contact Us" />
        <Hero
            backgroundImage={contactUsHeroBgUrl}
            title={contactUsHeroTitle}
            description={contactUsHeroDescription}
        />
        <ReportIssueForm />
    </Layout>
)

export default ReportIssuePage
